"use strict";
/*!
 *
 *  hero.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        // $(this.video).on('canplay', () => {
        // 	setTimeout(() => {
        // 		this.play();
        // 	}, 3000);
        // });
        var _this = this;
        this.$hero = $('#Hero');
        this.video = document.getElementById('heroVideo');
        $(this.video).on('canplay', function () {
            _this.play();
        });
    }
    default_1.prototype.play = function () {
        // this.video.play();
        this.$hero.addClass('-play');
    };
    return default_1;
}());
exports.default = default_1;
