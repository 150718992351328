"use strict";
/*!
 *
 *  header.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        var _this = this;
        this.$menu = $('#Menu');
        this.$btn = $('#MenuBtn');
        this.$global = $('#MenuGlobal a');
        this.$btn.on('click', function (e) {
            e.preventDefault();
            _this.$menu.toggleClass('-expanded');
            $('body').toggleClass('-show-menu');
        });
        this.$global.on('click', function (e) {
            if ($('body').is('.-show-menu')) {
                _this.$menu.removeClass('-expanded');
                $('body').removeClass('-show-menu');
            }
            ;
        });
    }
    return default_1;
}());
exports.default = default_1;
