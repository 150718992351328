"use strict";
/*!
 *
 *  header.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        var _this = this;
        this.$header = $('#Header');
        this.$hero = $('#Hero');
        this.setFloat();
        $(window).on('scroll', function () {
            _this.setFloat();
        });
    }
    default_1.prototype.setFloat = function () {
        var top = $(window).scrollTop();
        if (!this.$header.is('.-float') && top > 0) {
            this.$header.addClass('-float');
        }
        ;
        if (this.$header.is('.-float') && top <= 0) {
            this.$header.removeClass('-float');
        }
        ;
    };
    return default_1;
}());
exports.default = default_1;
